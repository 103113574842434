import React, { useState } from "react";
import { Provider } from "react-redux";
import GlobalStyles from "@iso/assets/styles/globalStyle";
import { store } from "./redux/store";
import Boot from "./redux/boot";
import Routes from "./router";
import AppProvider from "./AppProvider";
import { useEffect } from "react";
// import axios from "./library/helpers/axios";
import profileActions from "./redux/profile/actions";
import { useDispatch } from "react-redux";
import "react-chat-widget/lib/styles.css";
import { getToken, onMessageListener } from "./library/firebase/firebase";
import { notification } from "antd";
import useSound from "use-sound";
import notify from "../src/sounds/resume.wav";
import axios, { URL } from "./library/helpers/axios";
import { useSelector } from "react-redux";

// import {messaging} from '../src/library/firebase/firebase';
const App = () => {
  const [play] = useSound(notify);
  // messaging()
  const [isTokenFound, setTokenFound] = useState(false);

  onMessageListener()
    .then((payload) => {
      notification.open({
        message: payload.notification.title,
        description: payload.notification.body,
        duration: 3,
      });
      play();
    })
    .catch((err) => {});

  const sendToken = (token) => {
    axios
      .post(
        `/webToken`,
        { token },
        {
          headers: {
            Authorization: localStorage.getItem("id_token")
              ? `Bearer ${localStorage.getItem("id_token")}`
              : "",
          },
        }
      )
      .then((res) => {});
  };

  React.useEffect(() => {
    if (getRolesOptions() == "Supplier" && localStorage.getItem("id_token")) {
      getToken(setTokenFound);
      if (isTokenFound) {
        sendToken(isTokenFound);
      }
    }
  }, [isTokenFound]);
  return (
    <Provider store={store}>
      <AppProvider>
        <>
          <GlobalStyles />
          <Routes />
        </>
      </AppProvider>
    </Provider>
  );
};

if (localStorage.getItem("lang") == "ar") {
  document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
} else {
}

Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
const getRolesOptions = () => {
  const roles = localStorage.getItem("profile")
    ? JSON.parse(localStorage.getItem("profile")).role
    : false;

  return roles;
};
