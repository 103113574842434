import firebase from "firebase/app";
import "firebase/messaging";

import "firebase/firestore";
import "firebase/auth";
import ReduxSagaFirebase from "redux-saga-firebase";
import isoConfig from "@iso/config/firebase.config";
// Example if anyone want to use different config for production and development using .env
// const prodConfig = {
//   apiKey: process.env.REACT_APP_PROD_API_KEY,
//   authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
//   projectId: process.env.REACT_APP_PROD_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
// };
// const devConfig = {
//   apiKey: process.env.REACT_APP_DEV_API_KEY,
//   authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
//   projectId: process.env.REACT_APP_DEV_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
// };
// const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
// !firebase.apps.length ? firebase.initializeApp(isoConfig) : firebase.app();

export const firebaseApp = !firebase.apps.length
  ? firebase.initializeApp(isoConfig)
  : firebase.app();
export const auth = firebase.auth();
export const db = firebase.firestore();
export const rsf = new ReduxSagaFirebase(firebaseApp);
export default firebase;

const messaging = firebase.messaging();

export const getToken = async (x) => {
  try {
    await messaging.requestPermission();
    const token = await messaging.getToken();
    x(token);
    return token;
  } catch (error) {
    console.error(error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    console.log("msg firebase fired");
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

/**
 * Name : subscribeToNotifications()
 * Description: This method will request permission to user to show notification on browser.
 * If user allow to show push notifications then it will procide with rest of the steps.
 **/ export function subscribeToNotifications() {
  messaging
    .requestPermission()
    .then(() => messaging.getToken())
    .then((token) => {
      //   handleTokenRefresh(token)
    })
    .catch((err) => {
      console.log("error getting permission :(");
    });
}

/**
 * Name : handleTokenRefresh()
 * Description: This method passes token with firebase api to check if this token is already exist.
 * We also get topic subscription details in this method so we can check if user is already subscribed
 * with the tpoic or we need to call api to get him subscribe for topic.
 **/

// function handleTokenRefresh(token) {
// window.firebaseToken = token;
//       return request.doGetFirebase(API_ENDPOINT.formatUrl(API_ENDPOINT.firebaseTokenDetails,
//   window.firebaseToken))
//   .then((resp) => resp.json())
//   .then((res) => {
//     checkSubscription(res)
//   })
//   .catch(e => console.log("handleRefreshToken: "+e.message))
// }

/**
  * Name : checkSubscription()
  * Description: If registration token is not added in FCM then we add it to firebase 
  *  so we could send push notification to added tokens.
  *  Here we are sendign topic "firebase-myWebApp" and unique registration token
  *  of app to relate with each other.
                      // function checkSubscription(resp) {
                      //   if(resp) {
                      //     if(resp.status != 200 || !resp.authorizedEntity || !resp.rel) {
                      //       var reqBody = {
                      //         "to" : "/topics/firebase-myWebApp",
                      //         "registration_tokens" : [`${window.firebaseToken}`]
                      //       }
                      //       return request.doPostFirebase(API_ENDPOINT.firebaseBatchAddApi, reqBody)
                      //       .then((re) => relateAppInstanceWithTopic(re))
                      //       .catch(e => console.log("checkSubscription: "+e.message))
                      //     }
                      //   }
                      // }



    /**
  * Name : relateAppInstanceWithTopic()
  * Description: This method create relationship mapping with registration token and topic. 
  **/
// function relateAppInstanceWithTopic(res) {
//     if(res.status == 200 && !res.results) {
//       let params = [
//             window.firebaseToken,
//             'firebase-myWebApp'
//           ]
//       return request.doPostFirebase(API_ENDPOINT.formatUrl(API_ENDPOINT.mapTopicWithFirebaseInstance,
//       ...params))
//       .then((response) => {
//         if(response.status == 200) {
//           console.log('User is subscribed for push notification.')
//         } else {
//           console.log('User is not subscribed for push notification.')
//         }
//       })
//       .catch(e => console.log(e.message))
//     }
//   }

/**
 * Name : logoutFirebase()
 * Description: Delete current user token and also remove current token from firebase database (batch).
 */

//   export function logoutFirebase() {
//     messaging.getToken().then((token) => {
//       var fbToken = token;
//       messaging.deleteToken(token)
//       return fbToken;
//   })
//   .then((fbToken) => {
//     var reqBody = {
//         "to" : "/topics/firebase-firebase-myWebApp",                    //         "registration_tokens" : [`${fbToken}`]                    //       }
//       return request.doPostFirebase(API_ENDPOINT.firebaseBatchRemove, reqBody)
//       .then((res) => {
//         if(res.status != 200) {
//           console.log(res.results.error)
//         }
//       })
//       .catch(e => console.log("checkSubscription: "+e.message))
//   })
//   .catch((err) => {
//     console.log("error deleting token :(");
//   });

// }

/**
 * Name : onTokenRefresh()
 * Description: If token is deleted or expired , we need to create new token for current user
 * and need to add that token in the firebase database to get push notification.
 **/
// messaging.onTokenRefresh(function() {
//   messaging.getToken()
//       .then(function(refreshedToken) {
//         handleTokenRefresh(refreshedToken)
//         .then((resp) => { checkSubscription(resp)})
//         .catch((err) => {
//           console.log("error getting permission :(");
//         });

//       })
//       .catch(function(err) {
//         console.log('Unable to retrieve refreshed token ', err);
//       });
//   });

/**
 * Name : onMessage()
 * Description: When firebase receives push notification for current subscribed topic
 * this listner will get execute.
 **/
//  messaging.onMessage(function (payload) {
//   console.log("Message received. ", payload);

// });

// messaging.onBackgroundMessage((payload) => {
//   console.log('[firebase-messaging-sw.js] Received background message ', payload);
//   // Customize notification here
// });

const getRolesOptions = () => {
  const roles = localStorage.getItem("profile")
    ? JSON.parse(localStorage.getItem("profile")).role
    : false;

  return roles;
};

/*  supplier  */
if (getRolesOptions() == "Supplier" && localStorage.getItem("id_token")) {
  (function () {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/firebase-messaging-sw.js");
    }
  })();
} else {
  navigator.serviceWorker
    .getRegistrations()
    .then(function (registrations) {
      for (let registration of registrations) {
        console.log(registration);
        registration.unregister();
      }
    })
    .catch(function (err) {
      console.log("Service Worker registration failed: ", err);
    });
}

navigator.serviceWorker
  .getRegistrations()
  .then(function (registrations) {
    for (let registration of registrations) {
      console.log(registration);
      registration.unregister();
    }
  })
  .catch(function (err) {
    console.log("Service Worker registration failed: ", err);
  });

//   if ('serviceWorker' in navigator) {
//   window.addEventListener('load', function() {
//     navigator.serviceWorker.register('/sw.js').then(function(registration) {
//       // Registration was successful
//       console.log('ServiceWorker registration successful with scope: ', registration.scope);
//     }, function(err) {
//       // registration failed :(
//       console.log('ServiceWorker registration failed: ', err);
//     });
//   });
// }
