export default {
  databaseURL: "your_firebase_database_url",
  apiKey: "AIzaSyDXibcVvxVzrn5i1nF3FqH5tOyJ6vT5AV0",
  authDomain: "cut-admin.firebaseapp.com",
  projectId: "cut-admin",
  storageBucket: "cut-admin.appspot.com",
  messagingSenderId: "860542858671",
  appId: "1:860542858671:web:37925138009e37ed496b5a",
  measurementId: "G-FL08FPDT99"
};
