import { createGlobalStyle } from "styled-components";
import { palette, font } from "styled-theme";
// import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html[dir='rtl'] form .has-feedback > .ant-select .ant-select-arrow, form .has-feedback > .ant-select .ant-select-selection__clear, form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow, form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
  right: 12px !important;
}
.ant-menu-item {
  background-color: #ffff !important;
}
.ant-btn.ant-btn-primary{
  background: rgb(96, 205, 128);
  border-color: rgb(96, 205, 128);
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .table_spiner {
    text-align: center;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
  }
  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Roboto';
        font-family: Tahoma, Verdana, Segoe, sans-serif;

        font-weight: 400;
      }
    }
  }
  .avatar-uploader{
    width: auto !important;
  }
  .user_upload_img{
    position: relative;
    width: 100%;height: 100%;
  }
   .image_contant {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%)
    }
  .text-center {
    text-align: center;
  }
  @media screen and (max-width: 565px ) {
    .image_contant {
      position: sticky;
      transform: unset;
    }
  }
  /*-----------------------------------------------*/ 
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;
      
      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }
  .ql-editor{
  min-height: 250px !important;
  max-height: 300px;
  overflow: hidden;
 
}
  /*-----------------------------------------------*/ 
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


/*-----------------------------------------------*/ 
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: ${font("primary", 0)};
font-family: Tahoma, Verdana, Segoe, sans-serif;

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette("primary", 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${
    "" /* margin-left: -8px;
  margin-right: -8px; */
  };
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/********** Add Your Global CSS Here **********/

body {
  -webkit-overflow-scrolling: touch;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: 'Roboto', sans-serif;
  font-family: Tahoma, Verdana, Segoe, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/* Instagram Modal */

.ant-modal-wrap.instagram-modal .ant-modal {
  max-width: 935px;
  width: 100% !important;
}

@media only screen and (max-width: 991px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    max-width: 580px;
  }
}

.ant-modal-wrap.instagram-modal .ant-modal-content {
  border-radius: 0;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close {
  position: fixed;
  color: #fff;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close i {
  font-size: 24px;
}

.ant-modal-wrap.instagram-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}

/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */

html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */

html[dir='rtl'] .dz-hidden-input {
  display: none;
}



html[dir='rtl']  html h1, html h2, html h3, html h4, html h5, html h6, html a, html p, html li, input, textarea, span, div, html, body, html a {
  margin-bottom: 0;
  font-family: 'Cairo', sans-serif;
  font-family: Tahoma, Verdana, Segoe, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 0%);
}




html[dir='rtl']   .isomorphicSidebar .isoDashboardMenu .ant-menu-item-selected .nav-text {
  color: #6aaf65 !important;
  margin-right: 15px;
}






html[dir='rtl']   .isomorphicSidebar .isoDashboardMenu .anticon {

  margin-left: 15px;
}

html[dir='rtl']  .isomorphicTopbar.collapsed {
  padding: 0 109px 0 31px;
}
html[dir='rtl']   .isomorphicTopbar {
  padding:0 265px 0 31px;
}







html[dir='rtl'] .themeSwitchBlock h4 {
  margin-right: 0px;
}
html[dir='rtl'] .themeSwitchBlock div{
  margin-right: 0px;

}








html[dir='rtl']  .ant-menu-vertical .ant-menu-item::after {
  border-left: 3px solid #6aaf65;
  position: absolute;
  top: 0;
  right: 5px;
  bottom: 0;
  border-right: 3px solid #1890ff;
  -webkit-transform: scaleY(0.0001);
  transform: scaleY(0.0001);
  opacity: 0;
  -webkit-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}


html[dir='rtl']  .ant-table-wrapper {
  clear: both;
  max-width: 100%;
  text-align-last: center;
}


html[dir='rtl']  .ant-modal-content {
  text-align-last: right;
}


html[dir='rtl']  .ant-drawer .ant-drawer-content {
  text-align-last: right;

}


html[dir='rtl'] .ant-table-pagination.ant-pagination {
  text-align-last: auto;
  direction: ltr;
}
html[dir='rtl']   .ant-pagination-item {
  margin: 0 8px 0 0px;
}

html[dir='rtl'] .ant-drawer-close {
  position: absolute;
  top: 0;
  right:unset
  left: 0px;
}


html[dir='rtl'] .ant-input-textarea-show-count::after {
  display: block;
  color: rgba(0, 0, 0, 0.45);
  text-align: right;
  content: attr(data-count);
  text-align-last: end;
}

 
html[dir='rtl'] .fpEIeR.ant-btn {

  margin-left: 8px;
}

html[dir='rtl'] .ant-tabs-tab .anticon {
  margin-left: 12px;
  margin-right: 0px;

}







html[dir='rtl'] .anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // margin-right: 10px;
}



html[dir='rtl'] .ant-select {
  text-align: right;
}

html[dir='rtl'] .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
  text-align: end;
}


html[dir='rtl'] .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
  padding: 0px 10px;
}




html[dir='rtl'] .ant-tabs-tab {
  margin-left: 32px !important;
}






html[dir='rtl'] form .has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-left: 18px;
  padding-right: 0px;

}


html[dir='rtl'] form .has-feedback .ant-input {
  padding-right: 10px;
  padding-left: 1px;
  
}


html[dir='rtl']  .ant-modal-footer {
  padding: 10px 16px;
  text-align-last: end;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}
html[dir='rtl'] .has-error.has-feedback .ant-legacy-form-item-children-icon {
  color: #ff4d4f;
  animation-name: diffZoomIn2 !important;
  right: unset;
  
}



.has-success.has-feedback .ant-legacy-form-item-children-icon, .has-warning.has-feedback .ant-legacy-form-item-children-icon, .has-error.has-feedback .ant-legacy-form-item-children-icon, .is-validating.has-feedback .ant-legacy-form-item-children-icon {
  top: 1px;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
  padding: 5px;
}





html[dir='rtl'] .has-error.has-feedback .ant-legacy-form-item-children-icon {
  color: #ff4d4f;
  animation-name: diffZoomIn2 !important;
  display: inline-block;
  width: 5px !important;
  left: 20px !important;

}


.has-success.has-feedback .ant-legacy-form-item-children-icon {
  color: #52c41a;
  animation-name: diffZoomIn1 !important;
  width: 23px !important;
}
.has-error.has-feedback .ant-legacy-form-item-children-icon {
  color: #ff4d4f;
  animation-name: diffZoomIn2 !important;
  display: inline-block;
  width: 196.5% !important;
  left: -2px !important;
}
html[dir='rtl'] .has-success.has-feedback .ant-legacy-form-item-children-icon, .has-warning.has-feedback .ant-legacy-form-item-children-icon, .has-error.has-feedback .ant-legacy-form-item-children-icon, .is-validating.has-feedback .ant-legacy-form-item-children-icon {
  left: 2px;
  right:unset;
}
.ant-message {

  z-index: 99999 !important;

}

html[dir='rtl'] .ant-message {

  z-index: 99999 !important;

}

a:hover {
  color: #000 !important;
}
a:active {
  color: #000 !important;
}
a:visited {
  color: #000 !important;
}

.ant-image {
  position: relative;
  display: inline-block;
  margin: 10px;
}
`;

export default GlobalStyles;
